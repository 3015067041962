<template>
  <v-card min-height="300px" :color="color" dark class="d-flex flex-column">
    <v-card-title>
      <!-- <v-icon large left>{{ icon }}</v-icon>
      <span class="title font-weight-bold">{{ title }}</span> -->
      <div class="linha cor-titulo-card title font-weight-bold">
        <v-icon class="cor-titulo-card" large left>{{ icon }}</v-icon>{{ title }}
      </div>
      <div class="linha subtitle-text">{{ subtitle }}</div>    
    </v-card-title>
    <v-card-text class="subtitle-1 font-weight-light flex-grow-1 d-flex flex-column">
      <div class="text-center">
        <v-progress-circular
            :rotate="180"
            :size="100"
            :width="15"
            indeterminate
            :color="loadingColor"
            v-show="loading"
        >
        </v-progress-circular>
      </div>
      <v-card class="mb-4 custom-card">
        <v-card-text>
          <div v-for="element of dados" :key="element.item">
            <div class="cor-texto d-flex justify-space-between">
              <div>{{ element.item }}</div>
              <div class="font-weight-bold">{{ element.quantidade }}</div>
            </div>
            <painel-card-bar :minValue="1" backgroundColor="f5f5f5" :color="color" :total="total" :value="element.quantidade"/>
          </div>
          <slot name="footer"/>
        </v-card-text>
      </v-card>        
    </v-card-text>
  </v-card>
</template>

<script>
import PainelCardBar from "./PainelCardBar";
export default {
  name: "PainelCard",
  components: {PainelCardBar},
  props: ['icon', 'title', 'subtitle', 'color', 'loading', 'dados'],
  computed: {
    loadingColor() {
      return this.$utils.colors.pSBC(0.23, this.color);
    },
    total() {
      return this.dados.reduce((acumulado, elemento) => acumulado + elemento.quantidade, 0);
    }
  },
}
</script>
<style scoped>
.custom-card {
  background-color: #f5f5f5 !important; /* Cor de fundo cinza */
  border-left: 8px solid #888; /* Borda esquerda cinza mais escura */
}
.cor-texto {
  color:black;
}
.linha {
    width: 100%;
}
.subtitle-text {
  font-size: 0.8em; /* Tamanho da fonte */
  color: #FFFFFF; /* Cor do texto */
}
</style>