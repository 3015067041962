<template>
  <div class="page" :class="{'is-mobile' : $vuetify.breakpoint.mobile}">
    <div class="content">
      <v-container fluid>
        <v-row>
          <v-col cols="12" md="6" lg="3">
            <PainelDadosContratoCard icon="mdi-cash-multiple" title="Contratos" subtitle="Em andamento" color="#2A80B9" :loading="loadingEmAndamento"
                         :dados="itensContratosEmAndamento"/>
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <PainelDadosContratoCard icon="mdi-cash-multiple" title="Contratos" subtitle="Vencem em 30 dias" color="#F39C11" :loading="loadingVigencia"
                         :dados="itensContratosVigencia"/>
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <PainelDadosContratoCard icon="mdi-cash-multiple" title="Contratos" subtitle="Suspensos" color="#D93600" :loading="loadingSuspensos"
                         :dados="itensContratosSuspensos"/>
          </v-col>
          <v-col cols="12" md="6" lg="3" v-if="nivelUsuarioLogado !== 'CLI'" class="ultima-coluna">
            <PainelCard icon="mdi-message" title="Mensagens" subtitle="Histórico de envios do mês"
              :loading="loadingMensagem" color="#16A086" :dados="elements.ME">
              <!-- <template v-slot:footer v-if="(nivelUsuarioLogado === 'ADM' || nivelUsuarioLogado === 'GER') && !mostraLoad">
                <div class="d-flex flex-grow-1 flex-column justify-space-between mt-9">
                  <span class="white--text body-1">Integração Zap:</span>
                  <section class="d-flex align-self-end flex-column font-weight-bold">
                    <div class="item-zap text-end white--text">
                      <span class="subheading mr-2 whi">Saldo:</span>
                      <span class="subheading">{{ exibeSaldoZap }}</span>
                    </div>
                    <div class="item-zap text-end" :class="classeStatus">
                      <span class="subheading mr-2">Status:</span>
                      <span class="subheading"><v-icon :class="classeStatus">{{ exibeStatusZap }}</v-icon></span>
                    </div>
                  </section>
                </div>
              </template> -->
            </PainelCard>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import {
  findPainelFuncionario,
  findPainelContratos,
  // findSaldoIntegracaoZap,
  // findStatusIntegracaoZap
} from "../../api/painel";
import {mapGetters} from "vuex";
import PainelCard from "./components/PainelCard";
import PainelDadosContratoCard from "./components/PainelDadosContratoCard";
import {ApiError} from "../../api/core";

export default {
  name: "Painel",
  components: {PainelCard, PainelDadosContratoCard},
  data() {
    return {
      itensContratosEmAndamento: [],
      itensContratosSuspensos: [],
      itensContratosVigencia: [],
      elements: [],
      loadingMensagem: true,
      loadingEmAndamento: true,
      loadingSuspensos: true,
      loadingVigencia: true,
      error: null,
      saldoZap: null,
      statusZap: null,
      interval: {},
      value: 0,
      classeStatus: "",
    }
  },
  computed: {
    ...mapGetters('auth', ['user', 'isAuthenticated', 'nivelUsuarioLogado']),
    exibeSaldoZap() {
      return "R$ " + (this.saldoZap === null ? "0,00" : this.saldoZap);
    },
    exibeStatusZap() {
      let icone = "mdi-hand-okay";
      this.classeStatus = "conectado";
      if (this.statusZap !== "Conectado") {
        icone = "mdi-alert-circle";
        this.classeStatus = "desconectado";
      }
      return icone;
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  mounted() {
    this.refresh();
    this.contratosEmAndamento();
    this.contratosVigencia();
    this.contratosSuspensos();
  },
  methods: {
    async refresh() {
      try {
        this.error = null;
        this.loadingMensagem = true;
        let page = null;
        // let valor = null;
        page = await findPainelFuncionario(1, {});
        // if (this.nivelUsuarioLogado === 'ADM' || this.nivelUsuarioLogado === 'GER') {
        //   valor = await findSaldoIntegracaoZap(1, {});
        //   this.saldoZap = valor.elements.saldo;
        //   valor = await findStatusIntegracaoZap(1, {});
        //   this.statusZap = valor.elements.status;
        // }
        this.elements = page.elements;
      } catch (err) {
        this.error = err;
        this.elements = [];
        if (err instanceof ApiError) {
          this.$toast.error(err.message, {
            timeout: 6000
          })
        } else {
          this.$toast.error(`Não foi possível carregar a o painel.`, {
            timeout: 6000
          })
        }    
      } finally {
        this.loadingMensagem = false;
      }
    },
    async contratosEmAndamento() {
      try {
        this.error = null;
        this.loadingEmAndamento = true;
        const page = await findPainelContratos({tipo:"EA"});
        this.itensContratosEmAndamento = page.elements;
      } catch (err) {
        this.error = err;
        this.itensContratosEmAndamento = [];
        if (err instanceof ApiError) {
          this.$toast.error(err.message, {
            timeout: 6000
          })
        } else {
          this.$toast.error(`Não foi possível carregar os contratos em andamento.`, {
            timeout: 6000
          })
        }   
      } finally {
        this.loadingEmAndamento = false;
      }
    },
    async contratosSuspensos() {
      try {
        this.error = null;
        this.loadingSuspensos = true;
        const page = await findPainelContratos({tipo:"SU"});
        this.itensContratosSuspensos = page.elements;
      } catch (err) {
        this.error = err;
        this.itensContratosSuspensos = [];        
        if (err instanceof ApiError) {
          this.$toast.error(err.message, {
            timeout: 6000
          })
        } else {
          this.$toast.error(`Não foi possível carregar os contratos suspensos.`, {
            timeout: 6000
          })
        }   
      } finally {
        this.loadingSuspensos = false;
      }
    },
    async contratosVigencia() {
      try {
        this.error = null;
        this.loadingVigencia = true;
        const page = await findPainelContratos({tipo:"VG"});
        this.itensContratosVigencia = page.elements;
      } catch (err) {
        this.error = err;
        this.itensContratosVigencia = [];        
        if (err instanceof ApiError) {
          this.$toast.error(err.message, {
            timeout: 6000
          })
        } else {
          this.$toast.error(`Não foi possível carregar os contratos perto de vencer.`, {
            timeout: 6000
          })
        }   
      } finally {
        this.loadingVigencia = false;
      }
    },
  }
}
</script>
<style scoped lang="scss">
.page .is-mobile {
  .content {
    overflow: auto;
    max-height: calc(100vh - 60px);
  }

  ::v-deep {
    .v-data-table__wrapper {
      height: calc(100vh - 97px);
    }
  }
}

.page .content {
  /*border:2px solid green;*/
  overflow: auto;
  max-height: calc(100vh - 50px);
  width: 100%;
  flex: 1;

  ::v-deep {
    .v-data-table__wrapper {
      height: calc(100vh - 157px);
    }
  }
}

.ultima-coluna {
  margin-bottom: 200px;
}

.informacoes-zap {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;

  .item-zap {
    align-self: end;
  }
}

.conectado {
  color: #BAEEB9
}

.desconectado {
  color: #FF9595
}
</style>