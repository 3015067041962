<template>
  <v-card min-height="300px" :color="color" class="d-flex flex-column">
    <v-card-title class="cor-titulo-card">
      <div class="linha cor-titulo-card title font-weight-bold">
        <v-icon class="cor-titulo-card" large left>{{ icon }}</v-icon>{{ title }}
      </div>
      <div class="linha subtitle-text">{{ subtitle }} ({{ total }})</div>
    </v-card-title>
    <v-card-text class="subtitle-1 font-weight-light flex-grow-1 d-flex flex-column">
      <div class="text-center">
        <v-progress-circular
            :rotate="180"
            :size="100"
            :width="15"
            indeterminate
            :color="loadingColor"
            v-show="loading"
        >
        </v-progress-circular>
      </div>
      <v-card class="mb-4 custom-card" 
        v-for="element of dados" :key="element.item"
        :loading="loadingCard">
        <v-card-text class="subtitle-1 font-weight-light flex-grow-1 d-flex flex-column">
          <div>
            <div class="linha">
              <v-icon :color="color" small left>mdi-file-document-multiple-outline</v-icon>
              <span class="font-weight-bold">Contrato:</span> {{ element.numContrato }}
              <v-icon :color="color" @click="verRelatorio({id: element.id})" left title="Exibir relatório do contrato">mdi-file-pdf-box</v-icon>
            </div>
            <div class="linha">
              <v-icon :color="color" small left>mdi-clock-time-nine-outline</v-icon>
              <span class="font-weight-bold">Vigência:</span> {{ element.dataTerminoContrato }}
            </div>
            <div class="linha">
              <v-icon :color="color" small left>mdi-account-box-outline</v-icon>
              <span class="font-weight-bold">Contratado:</span> {{ element.descricaoContratado }}
            </div>
            <div class="linha">
              <v-icon :color="color" small left>mdi-account-group</v-icon>
              <span class="font-weight-bold">Fiscais: </span>

              <div class="linha">
                <v-chip class="mr-1" small v-if="element.responsavelAdmin !== ''" :color="loadingColor">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span v-on="on" class="white--text">{{ element.iniciaisResponsavelAdmin }}</span>
                    </template>
                    <span>Responsável Administrativo: {{ element.responsavelAdmin }}</span>
                  </v-tooltip>
                </v-chip>
                
                <v-chip class="mr-1" small v-if="element.responsavelFinan !== ''" :color="loadingColor">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span v-on="on" class="white--text">{{ element.iniciaisResponsavelFinan }}</span>
                    </template>
                    <span>Responsável Financeiro: {{ element.responsavelFinan }}</span>
                  </v-tooltip>
                </v-chip>
                
                <v-chip class="mr-1" small v-if="element.responsavelTecni !== ''" :color="loadingColor">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span v-on="on" class="white--text">{{ element.iniciaisResponsavelTecni }}</span>
                    </template>
                    <span>Responsável Técnico: {{ element.responsavelTecni }}</span>
                  </v-tooltip>
                </v-chip>
              </div>
            </div>
            <div class="linha font-weight-bold">
              <v-icon :color="color" small left>mdi-state-machine</v-icon>
              Status: {{ element.descStatus }}
            </div>
          </div>
        </v-card-text>
      </v-card>
      <slot name="footer"/>
    </v-card-text>
  </v-card>
</template>

<script>
import {relatorioContratoDataTable} from "../../../api/relatorios";

export default {
  name: "PainelCard",
  props: ['icon', 'title', 'subtitle', 'color', 'loading', 'dados'],
  data() {
    return {
      loadingCard: false,
    }
  },
  computed: {
    loadingColor() {
      return this.$utils.colors.pSBC(0.23, this.color);
    },
    total() {
      return this.dados.length;
    },
  },
  methods: {
    async verRelatorio(item) {
      item.formatoSaida = "pdf";
      item.tipoRelatorio = "AN";
      this.loadingCard = true;
      await relatorioContratoDataTable(item);
      this.loadingCard = false;
    },  
  } 
}
</script>
<style scoped>
.custom-card {
  background-color: #f5f5f5 !important; /* Cor de fundo cinza */
  border-left: 8px solid #888; /* Borda esquerda cinza mais escura */
}
.cor-titulo-card {
  color: #ffffff !important; /* Cor de fundo cinza */
}
.linha {
    width: 100%;
}
.subtitle-text {
  font-size: 0.8em; /* Tamanho da fonte */
  color: #FFFFFF; /* Cor do texto */
}
</style>